import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import EventPage from './pages/EventPage'
import AuthRoute from './util/AuthRoute'
import PrivateRoute from './util/PrivateRoute'
import ViewEvent from './pages/ViewEvent'
import Error from './pages/utils/Error'
import Login from './pages/Auth/Login'
import Signup from './pages/Auth/Signup'
import EventGraph from './pages/EventGraph'
import './App.css';
import './App.scss'
import {logOutUser} from './redux/actions/userActions'
import { Typography, Button }  from '@mui/material'
// Redux
import { connect } from 'react-redux' 
import CreateEvent from './pages/CreateEvent';
import axios from 'axios';






// import DisplayHeart from './pages/easter-eggs/displayHeart'
// import Heart from './pages/easter-eggs/Heart'

import DisplayHeart from './pages/easter-eggs/displayHeart'
import Heart from './pages/easter-eggs/Heart'

axios.defaults.baseURL = process.env.REACT_APP_API_HOST || 'http://localhost:5000/dkm-c3d3d/europe-west1/api';




function App(props) {
  const {auth} = props

  const authenticated = !auth.isEmpty
  
  if (authenticated) {
    const token = auth.stsTokenManager.accessToken
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  console.log("auth", authenticated)

  return (
    <Router >
      <Switch>
      <AuthRoute path="/signup" component={Signup} authenticated={authenticated} />
        <AuthRoute path="/login" component={Login} authenticated={authenticated}/>
        <PrivateRoute path="/event/create" component={CreateEvent} authenticated={authenticated}/>
        <PrivateRoute path="/event/:id/graph" component={EventGraph} authenticated={authenticated}/>
        <PrivateRoute path="/event/:id" component={EventPage} authenticated={authenticated}/>
        <Route path="/view-event/:id" component={(props) => <ViewEvent {...props} authenticated={authenticated}/>} />
        <PrivateRoute path="/error" component={Error} authenticated={authenticated}/>
        <PrivateRoute path="/" component={Home} authenticated={authenticated}/>
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth
})

const mapDispatchToProps = (dispatch) => {
  return {
    logOutUser: () => dispatch(logOutUser())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
