import { SET_ERRORS, CLEAR_ERRORS, LOADING_UI, REDIRECT_TO, SIGNIN_LOAD, SIGNIN_LOAD_DONE } from "../types";

const initialState = {
    loading: true,
    redirectTo: null,
    signin_loading: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type) {
        case LOADING_UI:
            return {
                ...state,
                loading: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                errors: null
            }
        case SET_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case REDIRECT_TO:
            return {
                ...state,
                redirectTo: action.payload
            }
        case SIGNIN_LOAD:
            return {
                ...state,
                signin_loading: true
            }
        case SIGNIN_LOAD_DONE:
            return {
                ...state,
                signin_loading: false
            }
        default:
            return state
    }
}