import {SET_EVENT_SHARING, EVENT_ERROR} from '../types'

import axios from 'axios';

export const sendSharing = (eventId, sharingData) => (dispatch) => {
    axios.put(`/event/${eventId}/set-sharing`, {sharing: sharingData})
    .then(res => {
        if (res.status === 204) {
            //TODO: Success message
        }
    })
    .catch(err => {
        dispatch({
            type: EVENT_ERROR,
            payload: err.response.data
        })
    })
}

export const updateSharing = (sharingData) => (dispatch) => {
    dispatch({
        type: SET_EVENT_SHARING,
        payload: sharingData
    })
}