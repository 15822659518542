import React from 'react';

const Footer = (props) => {
    const {transparent} = props;

    const styles = {
        backgroundColor: transparent ? "transparent" : "inherit"
    }

    return (
        <div className="footer" style={styles}>
            <p>Made with &lt;&gt;</p>
        </div>
    );
};

export default Footer;