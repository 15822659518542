import {
    SET_GENERIC_ERROR,
    SET_404_ERROR,
    SET_403_ERROR
} from '../types'



const initState = {
    isEmpty: true,
    properties: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action) {
    switch(action.type) {
        case SET_GENERIC_ERROR:
            return {
                ...state,
                isEmpty: false,
                properties: action.payload
            }
        case SET_404_ERROR:
            return {
                ...state,
                isEmpty: false,
                properties: {
                    status: 404
                }
            }
        case SET_403_ERROR:
            return {
                ...state,
                isEmpty: false,
                properties: {
                    status: 403,
                    message: "Action Unauthorized"
                }
            }
            
        default:
            return state
    }
} 
