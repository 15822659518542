import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles'
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {completeLoading} from '../../redux/actions/UIActions'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux' 
import { loginUser, googleLoginUser } from '../../redux/actions/userActions'
import Copyright from '../utils/Copyright';
import { Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Success from '../utils/Feedback/Success'

const styles = {
    form: {
        textAlign: 'center',
        width: "250px",
        margin: '0 auto',
        marginTop: "10em"
    },
    button: {
        marginTop: "1em"
    },
    small: {
        margin: "0 auto"
    }
}

class Login extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            email: "",
            password: "",
            errors: {},
            signupSuccess: false,
        }
    }

    componentDidMount() {
        const search = window.location.search;
        const queryParams = new URLSearchParams(search);

        console.log(this.props.auth)

        let redirectFromLogin = queryParams.get("signup");

        if (redirectFromLogin === "success") {
            this.setState({
                signupSuccess: true,
                email: queryParams.get("email")
            })
            queryParams.delete("signup")
            queryParams.delete("email")
            this.props.history.replace({
                search: queryParams.toString(),
            })
        }

        this.props.completeLoading()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            email: this.state.email,
            password: this.state.password
        }
        
        this.props.loginUser(data, this.props.history)
    }

    redirectSignup = e => {
        window.location = "/signup"
    }

    googleLogin = () => {
        this.props.googleLoginUser(this.props.history)
    }
    
    render() {
        const { errors } = this.state
        const { classes, UI: { loading } } = this.props

        return (
            <Container>
        

            <Grid container className={classes.form}>
                <Grid item sm>
                    <Typography variant="h2" className={classes.pageTitle}>
                        Login
                    </Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <TextField 
                            required 
                            fullWidth
                            name="email"
                            type="email"
                            id="email"
                            helperText={errors.email} 
                            error={errors.email ? true : false}
                            label="Email"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        <TextField
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            helperText={errors.password} 
                            error={errors.password ? true : false}
                            type="password"
                            autoComplete="on"
                            onChange={this.handleChange}
                            value={this.state.password}
                        />
                        <div className="auth-buttons">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={loading}
                            >Login</Button>
                            <div id="or"><strong>OR</strong></div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={loading}
                                onClick={this.redirectSignup}
                            >Signup</Button>
                        </div>

                        <br/>

                        <p style={{margin: 0}}><strong>You can also</strong></p>

                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={this.googleLogin}
                            disabled={loading}
                        >
                        Login with google
                        </Button>
                    
                        {loading && <CircularProgress />}
                    </form>
                </Grid>
            </Grid>
            {this.props.UI.signin_loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            )}

            <Copyright sx={{ mt: 5 }} />

            {this.state.signupSuccess &&
                <Success 
                    message="Signup was successful!"
                />
            }
            </Container>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    googleLoginUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        auth: state.firebase.auth,
        UI: state.ui,
        completeLoading: completeLoading
    }
}

const mapActionsToProps = {
    loginUser,
    googleLoginUser,
    completeLoading
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Login))
