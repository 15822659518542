
import React, { Component } from 'react';
import { connect } from 'react-redux' 
import PropTypes from 'prop-types'

import HeartImage from '../../assets/heart.png'

import {getCurrentHeartClicks,heartClick} from '../../redux/actions/eggActions'
import {Helmet} from "react-helmet";

class DisplayHeart extends Component {
    componentDidMount() {
        this.props.getCurrentHeartClicks()
    }

    render() {
        const {easterEgg} = this.props;

            return <div class="egg">
            <div className="heart"></div>
            <p className="text"></p>
            <p className="heart-clicks">{easterEgg.heartClicks}</p>
            <p className="link">love.dkm.io</p>
            <h1 className="mwl">Made with &lt;&gt;</h1>
            {/* <h2>{easterEgg.heartClicks}</h2>
            <button onClick={() => this.props.heartClick()}>Press me</button> 
            <img src={HeartImage}/> */}
            <Helmet>
              <script src="/heart-js.js" type="text/javascript" />
            </Helmet>
        </div>;
    }
}


DisplayHeart.propTypes = {
    easterEgg: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    easterEgg: state.egg,
})

const mapActionsToProps = {
    getCurrentHeartClicks,
}

export default connect(mapStateToProps, mapActionsToProps)(DisplayHeart)