import { 
    LOADING_UI
} from '../types'



export const completeLoading = () => (dispatch) => {
    dispatch({
        type: LOADING_UI,
        payload: false,
    })
}