import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

export default function Copyright(props) {
    return (
      <Typography className="copyright" variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.kth.pub">
          kth.pub
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'} 
      </Typography>
    );
  }