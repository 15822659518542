import { combineReducers } from 'redux'

import userReducer from './reducers/userReducer'
import dataReducer from './reducers/dataReducer'
import uiReducer from './reducers/uiReducer.js'
import authReducer from './reducers/authReducer'
import errorReducer from './reducers/errorReducer'
import eggReducer from './reducers/eggReducer'
import { firestoreReducer } from 'redux-firestore'

import {
    firebaseReducer
  } from 'react-redux-firebase'

const reducers = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    egg: eggReducer,
    error: errorReducer,
    auth: authReducer,
    user: userReducer,
    data: dataReducer,
    ui: uiReducer
})

export default reducers;