import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux'
import reducers from './redux/store'

import { configureStore } from '@reduxjs/toolkit'

import { createStore, applyMiddleware, compose } from 'redux'
import { reduxFirestore, getFirestore } from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import thunk from 'redux-thunk'
import firebase from './fire'

import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'


function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <div></div>;
  return children
}

const initialState = {}

const rrfConfig = {
  userProfile: "users", 
  useFirestoreForProfile: true,
  attachAuthIsReady: true,
  firebaseStateName: "firebase"
}

const middlewares = [
  thunk.withExtraArgument(getFirebase)
]


const store = createStore(reducers, initialState, compose(
  applyMiddleware(...middlewares),
))

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <App />
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
