import {
  SIGNUP_UNKNOWN_ERROR,
  SIGNUP_PASSWORD_ERROR,
  SIGNUP_EMAIL_ERROR,
  SIGNUP_NAME_ERROR,
  SIGNUP_ACCOUNT_EXISTS,
  SIGNUP_CLEAR_ERRORS,
} from '../types'

const initState = {
    authError: null,
    signupError: false,
    signupErrorData: {
      message: undefined
    }
}

const authReducer = (state = initState, action) => {
  switch(action.type) {
    case 'LOGIN_ERROR':
      return {
          authError: 'Login Failed',
          ...state
      }
    case 'LOGIN_SUCCESS':
      return {
          authError: null,
          ...state
      }
    case SIGNUP_ACCOUNT_EXISTS:
      console.log("hello")
      return {
        ...state,
        signupError: true,
        signupErrorData: {
          message: action.payload.message
        },
      }
    case SIGNUP_UNKNOWN_ERROR:
      return {

      }
    case SIGNUP_PASSWORD_ERROR:
        return {
  
        }
    case SIGNUP_EMAIL_ERROR:
      return {

      }
    case SIGNUP_NAME_ERROR:
      return {

      }
    case SIGNUP_CLEAR_ERRORS:
      return {
        ...state,
        signupError: false,
        signupErrorData: {
          message: undefined
        }
      }
    default:
        return state
  }
}

export default authReducer