import React, { Component } from 'react'
import {getGraphData, getEventData} from '../redux/actions/dataActions'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux' 
import EventGraphDisplay from './utils/Graphs/EventGraphDisplay'
import { Typography } from '@material-ui/core'
import Nav from './Navigation/Nav'
const styles = {
    title: {
        textAlign: "center",
    },
}

class EventGraph extends Component {
    constructor(props) {
        super(props)

        this.state = {
            eventId: undefined,
        }
    }

    componentDidMount() {
        const { match: { params }} = this.props
        this.setState({
            eventId: params.id
        })
        this.props.getEventData(params.id)
    }

    render() {
        const event = this.props.data.selectedEvent
        const {classes} = this.props

        const {eventError} = this.props.data
        if (this.props.UI.loading) return null;

        return [
            <Nav eventId={this.state.eventId}/>,
            <div>
                <Typography variant="h3" className={classes.title} id="event-title">
                    {event.name || "No-name Event"} Graph
                </Typography>
            </div>,
            <div class="graph-container">
                <EventGraphDisplay event={event}/>
            </div>
        ]
    }
}

EventGraph.propTypes = {
    getGraphData: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user,
    data: state.data,
    UI: state.ui,
    error: state.error,
})

const mapActionsToProps = {
    getGraphData,
    getEventData
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(EventGraph))
