import React from 'react'
import { useSelector, useDispatch  } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export const DisplayEventError = (props) => {
    const data = useSelector(state => state.data)
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
            return;
        }
    
        dispatch({
            type: 'CLEAR_EVENT_ERROR'
        })
    };

    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {data.eventError.error}!
            </Alert>
        </Snackbar>
    )
}
