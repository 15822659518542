import { SET_AUTHENTICATED, SET_UNAUTHENTICATED, SET_USER, ALL_USERS } from "../types";

const initialState = {
    authenticated: false,
    credentials: {},
    all_users: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                    authenticated: true
            }
        case SET_UNAUTHENTICATED:
            return {
                ...state,
                authenticated: false
            }
        case SET_USER:
            return {
                ...state,
                authenticated: true,
                ...action.payload
            }
        case ALL_USERS:
            return {
                ...state,
                all_users: action.payload,
            }
        default: 
            return state
    }
}