import { 
    GET_ALL_EVENTS, 
    SET_CURRENT_EVENT, 
    EVENT_ERROR, LOADING_UI, 
    SET_EVENT_UNSUBSCRIBE, 
    UPDATE_EVENT,
    SET_GENERIC_ERROR,
    SET_404_ERROR,
    SET_403_ERROR,
    SET_GRAPH_DATA,
    UNSUBSCRIBE_EVENT,
    SET_EVENT_SHARING
} from '../types'
import firebase, {db} from '../../fire'
import axios from 'axios'
import {logOutUser} from './userActions'


export const getAllEvents = (auth) => (dispatch, getState, {getFirebase}) => {
    dispatch({
        type: LOADING_UI,
        payload: true
    })
    axios
        .get("/events")
        .then(res => {
            dispatch({
                type: GET_ALL_EVENTS,
                payload: res.data
            })
            dispatch({
                type: LOADING_UI,
                payload: false
            })
        })
        .catch(err => {
            if (err.response.status === 403) {
                dispatch(logOutUser())
            }
        })
}

export const getEventData = (eventId, history) => (dispatch) => {
    axios
        .get(`/event/${eventId}`)
        .then(res => {
            dispatch({
                type: SET_CURRENT_EVENT,
                payload: res.data
            })
            dispatch({
                type: SET_EVENT_SHARING,
                payload: res.data.sharing
            })

            var unsubscribe = db.collection("events").doc(res.data.eventId)
            .onSnapshot((doc) => {
                dispatch({
                    type: UPDATE_EVENT,
                    payload: doc.data(),
                })
            }, (error) => {
                dispatch({
                    type: EVENT_ERROR,
                    payload: error.response
                })
            });
            dispatch({  
                type: SET_EVENT_UNSUBSCRIBE,
                payload: unsubscribe
            })
        })
        .catch(err => {
            console.log(err)
            if (err.response.status === 403) {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: {
                        status: 403,
                        message: "Unauthorized: Event access denied"
                    }
                })
                return;
            }

            if (err.response.status === 404) {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: {
                        status: 404,
                        message: "Event not Found"
                    }
                })
            }
        })
        .finally(() => dispatch({
            type: LOADING_UI,
            payload: false,
        }))
    
        
} 

export const getViewEvent = (eventId) => (dispatch) => {
    axios
        .get(`/view/${eventId}`)
        .then(res => {
            var unsubscribe = db.collection("events").doc(res.data.eventId)
            .onSnapshot((doc) => {
                dispatch({
                    type: UPDATE_EVENT,
                    payload: doc.data(),
                })
            }, (error) => {
                dispatch({
                    type: EVENT_ERROR,
                    payload: error.response.data
                })
            });
            dispatch({
                type: SET_EVENT_UNSUBSCRIBE,
                payload: unsubscribe
            })
        })
        .catch(err => {
            console.error(err)
        })
    

} 

export const eventIncrease = (eventId, history) => (dispatch) => {
    axios
        .put(`/event/${eventId}/in`)
        .then(res => {

        })
        .catch(err => {
            console.log(err)
            if (err.response.status === 400) {
                dispatch({
                    type: EVENT_ERROR,
                    payload: err.response.data
                })
            }
            if (err.response.status === 403) {
                console.log('hi', err)
                dispatch({
                    type: UNSUBSCRIBE_EVENT
                })
                window.location = "/"
            }
            else {
                console.error(err)
            }
        })
}

export const eventDecrease = (eventId) => (dispatch) => {
    axios
        .put(`/event/${eventId}/out`)
        .then(res => {})
        .catch(err => {
            if (err.response.status === 400) {
                dispatch({
                    type: EVENT_ERROR,
                    payload: err.response.data
                })
            }
            else if (err.response.status === 403) {
                dispatch({
                    type: UNSUBSCRIBE_EVENT,
                })
            }
            else {
                console.error(err)
            }
        })
}

export const createEvent = (eventData, history) => (dispatch) => {
    axios
        .post("/event", eventData)
        .then(res => {
            if (res.status === 200) {
                window.location = "/"
            }
        })
        .catch(err => {
            if (err.response.status === 400) {
                dispatch({
                    type: EVENT_ERROR,
                    payload: err.response.data
                })
            }
            else {
                console.error(err)
            }
        })
}

export const removeEvent = (eventId, history) => (dispatch) => {
    axios
        .delete(`/event/${eventId}`)
        .then(res => {
            window.location = "/"
        })
        .catch(err => {
            console.error(err)
        })
}

export const getGraphData = (eventId) => (dispatch) => {
    dispatch({
        type: LOADING_UI,
        payload: true,
    })

    axios
        .get(`/event/${eventId}/graph`)
        .then(res => {
            console.log("res", res.data)
            dispatch({
                type: SET_GRAPH_DATA,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
        .finally(() => dispatch({
            type: LOADING_UI,
            payload: false,
        })
    )
}   