import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { connect } from 'react-redux' 
import Copyright from '../../utils/Copyright'

import React from 'react'

export default function GroupSignup(props) {

  return (
	<Container component="main" maxWidth="xs">
	<CssBaseline />
	  <Box component="form" noValidate onSubmit={props.handleSubmit} sx={{ mt: 3 }}>
		<Grid container spacing={1}>
		  <Grid item xs={12}>
			<TextField
				value={props.data.groupName}
				onChange={props.handleChange}
			  name="groupName"
			  required
			  fullWidth
			  id="groupName"
			  label="Group Name"
			  autoFocus
			/>
		  </Grid>
		  <Grid item xs={12}>
			<TextField
        value={props.data.groupEmail}
        onChange={props.handleChange}
			  required
			  fullWidth
			  id="email"
			  label="Shared Email Address"
			  name="groupEmail"
			  autoComplete="email"
			/>
		  </Grid>
		  <Grid item xs={12}>
			<TextField
        value={props.data.password}
        onChange={props.handleChange}
			  required
			  fullWidth
			  name="password"
			  label="Password"
			  type="password"
			  id="password"
			  autoComplete="new-password"
			/>
		  </Grid>
          <Grid item xs={12}>
			<TextField
        value={props.data.confirmPassword}
        onChange={props.handleChange}
			  required
			  fullWidth
			  name="confirmPassword"
			  label="Confirm Password"
			  type="password"
			  id="confirmPassword"
			  autoComplete="new-password"
			/>
		  </Grid>
		  <Grid item xs={12}>
			<FormControlLabel
			  control={<Checkbox color="primary" name="acceptBox" 
        required
        checked={props.data.acceptBox} onChange={props.handleCheckbox} />}
			  label="GDPR Box"
			/>
		  </Grid>
		</Grid>
		<Button
		  type="submit"
		  fullWidth
		  variant="contained"
		  sx={{ mt: 3, mb: 2 }}
		>
		  Sign Up
		</Button>
		<Grid container justifyContent="flex-end">
		  <Grid item>
			<Link href="#" variant="body2">
			  Already have an account? Sign in
			</Link>
		  </Grid>
		</Grid>
	  </Box>
  </Container>
  )
}
