import {
    SET_USER, 
    ALL_USERS,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP_FAILED,
    SIGNUP_ACCOUNT_EXISTS,
    SIGNIN_LOAD,
    SIGNIN_LOAD_DONE
} from '../types'
import { getErrorType, getErrorMessage } from './signUpErrors';
import provider from '../../setupGoogleAuth'
import firebase, {auth, db} from '../../fire';
import axios from 'axios'

export const loginUser = (userData) => (dispatch, getState, {getFirebase}) => {
    dispatch({type: SIGNIN_LOAD})
    auth.signInWithEmailAndPassword(userData.email, userData.password)
    .then((data) => {        
        return data.user
    })
    .then(user => {
        dispatch({type: 'LOGIN_SUCCESS'})
        window.location = "/"
    })
    .catch(err => {
        dispatch({type: 'LOGIN_FAILED', payload: err})
    })
    .finally(() => {
        dispatch({type: SIGNIN_LOAD_DONE})
    })
}

export const googleLoginUser = (history) => async (dispatch, getState, {getFirebase}) => {
    const res = await auth.signInWithPopup(provider)
    const user = res.user;
    const query = await db
      .collection("users")
      .where("uid", "==", user.uid)
      .get();
    if (query.docs.length === 0) {
      await db.collection("users").add({
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        userHandle: "default"
      });
    }
    
    dispatch({type: 'LOGIN_SUCCESS'})
    window.location = "/"
    
}

export const logOutUser = () => (dispatch, getState, {getFirebase}) => {
    
    auth.signOut()
    .then(() => {
        window.location = "/login"
        return;
    })
}

export const getUserData = () => (dispatch) => {
    axios.defaults.headers.common["Authorization"] = localStorage.FBIdToken

    axios.get('/user')
        .then(res => {
            dispatch({
                type: SET_USER,
                payload: res.data
            })
        })
        .catch(err => {
            console.error(err)
        })
}

export const getUsers = () => (dispatch) => {
    axios.get('/all-users')
    .then(res => {
        dispatch({
            type: ALL_USERS,
            payload: res.data
        })
    })
    .catch(err => {
        console.log(err)
    })
}

export const performSignup = (history, newUserData) => async (dispatch) => {
    dispatch({type: SIGNIN_LOAD})

    const query = await db
        .collection("users")
        .where("email", "in", [newUserData.email, newUserData.groupEmail])
        .get();

    // User already exists
    if (query.docs.length > 0) {
        dispatch({type: SIGNUP_ACCOUNT_EXISTS, payload: {
            message: "Account already exists!"
        }})
        dispatch({type: SIGNIN_LOAD_DONE})
        // TODO: Error message
        return;
    } else {
        axios.post("/signup", newUserData)
        .then(res => {
            dispatch({
                type: SIGNUP_SUCCESS
            })
            dispatch({type: SIGNIN_LOAD_DONE})
            window.location = `/login?signup=success&email=${newUserData.email}`
        })
        .catch(err => {
            const errorType = getErrorType(err.response)
            const message = getErrorMessage(err.response)
            console.log(errorType)
            dispatch({
                type: errorType,
                payload: {
                    message: message
                }
            })
            dispatch({type: SIGNIN_LOAD_DONE})
        })
    }

}