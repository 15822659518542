import { Button, CircularProgress, Grid, TextField, Typography, Slider, Input, FormControlLabel, Checkbox  } from '@material-ui/core';
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { Component } from 'react';

import {createEvent} from '../redux/actions/dataActions'
import {completeLoading} from '../redux/actions/UIActions'

import {connect} from 'react-redux'
import Nav from './Navigation/Nav'

function parseISOLocal(s) {
    var b = s.split(/\D/);
    return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
  }

const styles = {
    form: {
        textAlign: 'center',
        width: "350px",
        margin: '0 auto',
        marginTop: "1em",
        padding: "2em 0"
    },
    button: {
        marginTop: "1em"
    },
    small: {
        margin: "0 auto"
    }
}

class CreateEvent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: {},
            eventName: 'Onsdagspub',
            description: 'Cool freaking event!',
            showMaxCap: false,
            maxEntry: 50,
            hasTicketRelease: false,
            ticketReleaseForm: "",
            ticketReleaseName: "",
            ticketReleaseDate: Date.now(),
            eventDate: Date.now()
        }
    }

    componentDidMount() {
        this.props.completeLoading()
    }

    componentDidUpdate() {
        // TODO: Make checkbox for tickets true if start typing or setting date
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const eventData = {
            name: this.state.eventName,
            description: this.state.description || '',
            maxEntry: this.state.maxEntry,
            showMaxCap: this.state.showMaxCap,
            date: this.state.eventDate,
            hasTicketRelease: this.state.hasTicketRelease,
            ticketReleaseName: this.state.ticketReleaseName,
            ticketReleaseForm: this.state.hasTicketRelease ? this.state.ticketReleaseForm : null,
            ticketReleaseDate: this.state.hasTicketRelease ? new Date(this.state.ticketReleaseDate) : null,
        }
        this.props.createEvent(eventData, this.props.history)
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleChangeChecked = e => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    handleSliderChange = (e, value) => {
        this.setState({
            maxEntry: value
        })
    }


    render() {
        const { errors } = this.state
        const { classes, UI: { loading } } = this.props

        return (
            <Grid container className={classes.form}>
                <Nav />
                <Grid item sm>
                    <Typography variant="h2" className="page-title">
                        Skapa Event
                    </Typography>
                    <form noValidate onSubmit={this.handleSubmit} id="create-event-form">
                        <TextField 
                            required 
                            fullWidth
                            name="eventName"
                            type="text"
                            id="eventName"
                            helperText={errors.eventName} 
                            error={errors.eventName ? true : false}
                            label="Namn"
                            className={classes.textField}
                            value={this.state.eventName}
                            onChange={this.handleChange}
                        />
                        <TextField 
                            fullWidth
                            multiline
                            rows={4}
                            name="description"
                            defaultValue="Coolt jävla event"
                            type="text"
                            id="description"
                            helperText={errors.description} 
                            error={errors.description ? true : false}
                            label="Beskrivning"
                            className={classes.textField}
                            value={this.state.description}
                            onChange={this.handleChange}
                        />
                        <div>
                            <Typography id="range-slider" gutterBottom>
                                Övre gräns
                            </Typography>
                            <Slider
                                value={this.state.maxEntry}
                                onChange={this.handleSliderChange}
                                name="maxEntry"
                                aria-labelledby="input-slider"
                                valueLabelDisplay="auto"
                                step={5}
                                marks
                                min={10}
                                max={200}
                            />
                            <Input
                                required
                                value={this.state.maxEntry}
                                margin="dense"
                                name="maxEntry"
                                onChange={this.handleChange}
                                helperText={errors.maxEntry} 
                                error={errors.maxEntry ? true : false}
                                inputProps={{
                                step: 5,
                                min: 10,
                                max: 200,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                                }}
                            />
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.showMaxCap}
                                    name="showMaxCap"
                                    type="checkbox"
                                    onChange={this.handleChangeChecked}
                                    helperText={errors.showMaxCap} 
                                    error={errors.showMaxCap ? true : false}
                                />
                            }
                            label="Visa övre gräns"
                        />
                        
                        <div className="fw">

                            <TextField
                                id="datetime-local"
                                label="Event datum"
                                name="eventDate"
                                type="datetime-local"
                                value={this.state.eventData}
                                onChange={this.handleChange}
                                helperText={errors.date} 
                                error={errors.maxEntry ? true : false}
                                className={classes.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                        </div>

                        <br/>
                        <Typography variant="h5">
                            Ticket Release
                        </Typography>


                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.hasTicketRelease}
                                    name="hasTicketRelease"
                                    type="checkbox"
                                    onChange={this.handleChangeChecked}
                                    helperText={errors.hasTicketRelease} 
                                    error={errors.hasTicketRelease ? true : false}
                                />
                            }
                            label="Har biljettsläpp"
                        />
                        {this.state.hasTicketRelease && [
                            <TextField 
                            required 
                            fullWidth
                            name="ticketReleaseName"
                            type="text"
                            id="ticketReleaseName"
                            helperText={errors.ticketReleaseName} 
                            error={errors.ticketReleaseName ? true : false}
                            label="Bilhettsläppstitel"
                            className={classes.textField}
                            value={this.state.ticketReleaseName}
                            onChange={this.handleChange}
                        />,
                        <TextField
                            id="datetime-local"
                            label="Datum & tid för biljettsläpp"
                            name="ticketReleaseDate"
                            type="datetime-local"
                            value={this.state.ticketReleaseDate}
                            onChange={this.handleChange}
                            helperText={errors.ticketReleaseDate} 
                            error={errors.ticketReleaseDate ? true : false}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />,

                        <TextField
                            fullWidth
                            label="Länk till biljettsläppformuläret"
                            name="ticketReleaseForm"
                            type="text"
                            value={this.state.ticketReleaseForm}
                            onChange={this.handleChange}
                            helperText={errors.ticketReleaseForm} 
                            error={errors.ticketReleaseForm ? true : false}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        ]}

                        <br/>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={loading}
                        >Create Event</Button>

                        <br/>
                        
                        <a href="/events">
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                disabled={loading}
                            >Cancel</Button>
                        </a>
                    
                        {loading && <CircularProgress />}
                    </form>
                </Grid>
            </Grid>
        );
    }
}


CreateEvent.propTypes = {
    createEvent: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    completeLoading: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.ui
})

const mapActionsToProps = {
    createEvent,
    completeLoading
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(CreateEvent))
