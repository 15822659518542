import { 
    GET_ALL_EVENTS,
    SET_CURRENT_EVENT,
    EVENT_ERROR, 
    CLEAR_EVENT_ERROR, 
    SET_EVENT_UNSUBSCRIBE, 
    REMOVE_EVENT_UNSUBSCRIBE,
    UPDATE_EVENT,
    UNSUBSCRIBE_EVENT,
    SET_EVENT_SHARING,
    SET_GRAPH_DATA
} from "../types";

const initialState = {
    events: [],
    selectedEvent: null,
    selectedEventSharing: [],
    eventError: null,
    unsubscribe: null,
    graphData: [],
}


// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_EVENTS:
            return {
                ...state,
                events: action.payload,
                unsubscribe: null,
            }
        case SET_CURRENT_EVENT:
            return {
                ...state,
                selectedEvent: action.payload
            }
        case EVENT_ERROR:
            return {
                ...state,
                eventError: action.payload,
            }
        case CLEAR_EVENT_ERROR:
            return {
                ...state,
                eventError: null
            }
        case SET_EVENT_UNSUBSCRIBE:
            return {
                ...state,
                unsubscribe: action.payload
            }
        case REMOVE_EVENT_UNSUBSCRIBE:
            return {
                ...state,
                unsubscribe: null
            }
        case UPDATE_EVENT:
            return {
                ...state,
                selectedEvent: action.payload
            }
        case SET_EVENT_SHARING:
            return {
                ...state,
                selectedEventSharing: action.payload,
            }
        case UNSUBSCRIBE_EVENT:
            return {
                ...state,
                unsubscribe: null,
                selectedEvent: null,
            }
        case SET_GRAPH_DATA:
            return {
                ...state,
                graphData: action.payload
            }
        default:
            return state
    }
}