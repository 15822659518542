import React, { Component } from 'react'

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux' 
import { getUserData } from '../redux/actions/userActions'
import { getAllEvents } from '../redux/actions/dataActions'
import PropTypes from 'prop-types'

// Material
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Footer from './utils/Footer'
import Logout from './utils/Logout'

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Nav from './Navigation/Nav';


const styles = {
    root: {
      width: '100%',
      maxWidth: '36ch',
    },
    inline: {
      display: 'inline',
    }
}

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            autoUpdate: false
        }
    }

    componentDidMount() {
        console.log("hello")
        this.props.getAllEvents(this.props.auth)

        setInterval(() => {
            if (this.state.autoUpdate) {
                this.props.getAllEvents()
            }
        }, 5000)
    }

    render() {
        const classes = styles;
        const {events} = this.props.data
        

        return (
            <div id="all-events">
            <Nav />
            <Fab color="primary" aria-label="add" className="add-event-button" href="/event/create">
                <AddIcon />
            </Fab>

            <div className="home-page-title">
                <Typography variant="h1">
                    Pub Clicker
                </Typography>
            </div>

            {this.props.UI.loading && <LinearProgress style={{marginTop: "1em"}} />}
            <List className={classes.root}>
                {events.map((event, index) => {
                    const now = new Date()
                    const date = new Date(event.date)
                    // Ensure that only future events are seen, minus 7 days
                    let lastShowDate = new Date().setTime(now.getTime() - (7 * 24 * 60 * 60 * 1000))
                    lastShowDate = new Date(lastShowDate)

                    if (date < lastShowDate) return null

                    return (
                        <a href={`/event/${event.eventId}`} key={event.eventId}>
                        <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={event.name || "No-name Event"}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    
                                </Typography>
                                {event.description || "Ingen beskrivning hittades"} | {`${date.toDateString()} ${date.getHours()}:${date.getMinutes()}` || "Inget Datum"} <br/>Lo
                                </React.Fragment>
                            }
                        />
                        <p>
                            <Chip label={`${event.clicks}/${event.maxEntry}`} color={!event.isActive ? "primary" : "secondary"}/>
                        </p>

                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </a>
                    )
                })}
            </List>
            {/* <div className="create-new-event">
                <Button variant="contained" color="primary" href="/event/create">
                    Skapa nytt Event
                </Button>
            </div> */}
            <Footer />
        </div>
        )
    }
}

Home.propTypes = {
    getUserData: PropTypes.func.isRequired,
    getAllEvents: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user,
    data: state.data,
    auth: state.firebase.auth,
    UI: state.ui
})

const mapActionsToProps = {
    getUserData,
    getAllEvents
}


export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Home))