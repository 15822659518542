import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect  } from "react-router-dom";

import { Typography } from '@material-ui/core'
import {CLEAR_EVENT_ERROR} from '../../redux/types'
import ErrorImg from './assets/error.png'
import Button from '@mui/material/Button';

const Error = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
           dispatch({
               type: CLEAR_EVENT_ERROR
           })
        }
    }, [])


    const state = useSelector(state => state.error)
    if (state.isEmpty) return <Redirect to="/" />

    const message = state.properties.message || "Unknown Error"
    const status = state.properties.status || "500"

    return (
        <div id="not-found">
            <div>
                <img src={ErrorImg} alt="404 not found" id="img-404"/>
                <Typography variant="h2">
                    Error : {status}
                </Typography>
                <Typography variant="h4">
                    {message}
                </Typography>

                <Button variant="contained" onClick={() => <Redirect to="/"/>} style={{marginTop: "50px"}}>Go Home</Button>
            </div>
        </div>
    );
};

export default Error;
