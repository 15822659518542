import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { connect } from 'react-redux' 
import Copyright from '../utils/Copyright'

import GroupSignup from './Signup-methods/GroupSignup'
import IndividualSignup from './Signup-methods/IndividualSignup'

import { useNavigate  } from "react-router-dom";
import {performSignup} from '../../redux/actions/userActions'
import AuthError from '../utils/Feedback/AuthError';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


class Signup extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      tabIndex: 0,
      
      formData: {
        accountType: 'individual',
        groupName: '',
        groupEmail: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptBox: false,
      }
    }
  }

  handleChange = e => {
    this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]: e.target.value
        }
    })
  }

  handleCheckbox = e => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.checked
      }
  })
  }

  handleTabChange = (_, newValue) => {
    this.setState({
      tabIndex: newValue,
      formData: {
        ...this.state.formData,
        accountType: newValue === 0 ? 'individual' : 'group',
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = this.state.formData;

    this.props.performSignup(this.props.history, data);
  }

  render() {
    const tab = this.state.tabIndex;

    return (
      <Container component="main" maxWidth="xs">
          <Box
            sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h2">
            Sign up
            </Typography>
            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={this.handleTabChange} aria-label="basic tabs example">
                <Tab label="Individual Account" />
                <Tab label="Group Account" />
              </Tabs>
            </Box>
            <AuthError />
            
            <TabPanel value={tab} index={0}>
              <IndividualSignup
                data={this.state.formData}
                handleChange={this.handleChange}
                handleCheckbox={this.handleCheckbox}
                handleSubmit={this.handleSubmit}  
              />
            </TabPanel>
            {this.props.UI.signin_loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            )}

            <TabPanel value={tab} index={1}>
              <GroupSignup
                data={this.state.formData}
                handleChange={this.handleChange}
                handleCheckbox={this.handleCheckbox}
                handleSubmit={this.handleSubmit}  
              />
            </TabPanel>

            
          </Box>
          <Copyright sx={{ mt: 5 }} />
          
        </Container>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        auth: state.firebase.auth,
        UI: state.ui,
    }
}

const mapActionsToProps = {
  performSignup
}

export default connect(mapStateToProps, mapActionsToProps)(Signup)
