import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Divider, Fab, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { logOutUser } from '../../redux/actions/userActions'
import PreviewIcon from '@mui/icons-material/Preview';

import { useDispatch } from 'react-redux';

export default function Nav({eventId}) {
    const [toggled, toggleOpen] = React.useState(false)
    const dispatch = useDispatch();

    return (
        <React.Fragment >
        <Fab color="primary" className="menu-drawer-button" onClick={() => toggleOpen(true)}>
            <MenuIcon />
        </Fab>
        <SwipeableDrawer
          open={toggled}
          onClose={() => toggleOpen(false)}
          onOpen={() => toggleOpen(true)}
        >
            <Box sx={{
                width: 300
            }}>
                <List >
                    <ListItem>
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <Link href="/">
                            <ListItemText primary={"Home"}/>
                        </Link>
                    </ListItem>
                    {eventId &&
                        <ListItem>
                            <ListItemIcon>
                                <GroupAddIcon/>
                            </ListItemIcon>
                            <Link href={`/event/${eventId}`}>
                                <ListItemText primary={"Event"}/>
                            </Link>
                        </ListItem>
                    }
                    {eventId &&
                        <ListItem>
                            <ListItemIcon>
                                <PreviewIcon/>
                            </ListItemIcon>
                            <Link href={`/view-event/${eventId}`}>
                                <ListItemText primary={"View Event"}/>
                            </Link>
                        </ListItem>
                    }
                    {eventId && 
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon/>
                            </ListItemIcon>
                            <Link href={`/event/${eventId}/graph`}>
                                <ListItemText primary={"Graph"}/>
                            </Link>
                        </ListItem>
                    }
                    <Divider />
                    <ListItem style={{marginTop: "1em"}}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <Link href="/logout">
                            <Button variant="outlined" onClick={() => dispatch(logOutUser())}>Logout</Button>
                        </Link>
                    </ListItem>

                </List>
            </Box>
        </SwipeableDrawer>
      </React.Fragment>
    );
};

