import React, { Component } from 'react'
import Plot from 'react-plotly.js';

import {getGraphData, getEventData} from '../../../redux/actions/dataActions'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux' 
import { Typography } from '@material-ui/core'

class EventGraphDisplay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      layout: {
        title: props.event.name,
        autosize: true,
        xaxis: {
          autorange: true,
          rangeselector: {buttons: [
              {
                count: 1,
                label: '1h',
                step: 'hour',
                stepmode: 'backward'
              },
              {
                count: 30,
                label: '30min',
                step: 'minute',
                stepmode: 'backward'
              },
              {
                count: 5,
                label: '5h',
                step: 'hour',
                stepmode: 'backward'
              },
              {step: 'all'}
            ]},
          rangeslider: {range: [new Date(props.event.eventUnix), new Date(props.event.eventUnix) + 3600]},
          type: 'date'
        },
        yaxis: {
          autorange: true,
          type: 'linear'
        }
      }
    }
  }

  componentDidMount() {
    
  }

  render() {
    const event = this.props.event

    if (!event) return null;

    const gdata = event.tracking;

    const dates = gdata.map((row) => new Date(row.time))
    const clickData = gdata.map((row) => row.clicks)

    const eventStartDate = new Date(event.eventUnix)
    console.log(eventStartDate, eventStartDate + 3600 * 12)

    return (
        <Plot
        data={[
          {
            x: dates,
            y: clickData,
            type: 'scatter',
            mode: 'lines',
            marker: {color: 'red'},
          },
        ]}
        layout={ this.state.layout }
        useResizeHandler={true}
        config={{responsive: true}}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  UI: state.ui,
  error: state.error,
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(EventGraphDisplay)
