import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {Redirect} from 'react-router'
import {useParams} from 'react-router-dom'
import { connect } from 'react-redux' 
import {getUsers} from '../redux/actions/userActions';
import {sendSharing, updateSharing} from '../redux/actions/sharingActions'
import { getEventData, eventIncrease, eventDecrease, removeEvent } from '../redux/actions/dataActions'
import { Button, FormControlLabel, Typography, Switch, Accordion, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/core/styles/withStyles'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DisplayEventError } from '../util/DisplayEventError'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Counter from './event/Counter'

import Heart from '../assets/heart.png'
import BrokenHeart from '../assets/broken-heart.svg'
import Nav from './Navigation/Nav';

function Event() {
    const params = useParams();

    console.log(params)
}

const styles = {
    title: {
        textAlign: "center",
    },
    back: {
        marginLeft: "10px",
        opacity: "75%",
        position: "fixed",
        top: "10%",
        zIndex: "100",
        backgroundColor: "black",
        borderRadius: "50%",
        padding: "5px"
    },
    editEvent: {
        width: '75%',
        margin: '0 auto',
        textAlign: 'center',
        minHeight: "200px"
    },
    button: {
        height: "50px",
        margin: '1em 0',
    }
}


class EventPage extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)

        this.state = {
            eventId: null,
            editMode: false ,
            open: false,
            event_sharing: [],
        }

        this.updateSharing = this.updateSharing.bind(this)
        this.setSharing = this.setSharing.bind(this)
    }

    componentDidMount() {
        console.log(this.props.globalStore)
        const { match: { params }} = this.props
        this.setState({
            eventId: params.id
        })
        this.props.getEventData(params.id)
        this.props.getUsers()
    }
    
    componentWillUnmount() {
        this.props.data.unsubscribe && this.props.data.unsubscribe()
    }

    handleIncrease = (event) => {
        const eventId = this.state.eventId
        this.props.eventIncrease(eventId, this.props.history)
    }

    handleDecrease = (event) => {
        const eventId = this.state.eventId
        this.props.eventDecrease(eventId)
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    removeEvent = (event) => {
        this.props.removeEvent(this.state.eventId, this.props.history)    
    }

    handleClose = event => {
        this.setState({
            open: false
        })
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    }

    renderCounter = () => {
        const {clicks, maxEntry, showMaxCap} = this.props.data.selectedEvent

        if (showMaxCap) 
            return (
                <Typography variant="h4" className={`clicks ${clicks === maxEntry ? 'max-entry' : ''}`}>
                    {clicks} / {maxEntry}
                </Typography>   
            )
        else {
            const percentageSpaceLeft = 1 - clicks / maxEntry;
            let colorIndicator = "white";

            if (percentageSpaceLeft < 0.1) colorIndicator = "red";
            else if (percentageSpaceLeft < 0.2) colorIndicator = "orange";
            else if (percentageSpaceLeft < 0.3) colorIndicator = "yellow"
            
            return (
                <Typography variant="h4" className={`clicks ${clicks === maxEntry ? 'max-entry' : ''}`} style={{color: colorIndicator}}> 
                    {clicks}
                </Typography>   
            )
        }
    }

    handleError(err) {
        if (!err.isEmpty)
            return <Redirect to="/error"/>
    }

    updateSharing(event, newValue) {
        this.props.updateSharing(newValue)
    }

    setSharing() {
        this.props.sendSharing(this.state.eventId, this.props.data.selectedEventSharing)
    }

    render() {
        const event = this.props.data.selectedEvent
        const {classes} = this.props

        const {eventError} = this.props.data
        if (!this.props.error.isEmpty)
            return this.handleError(this.props.error)

        if (!event) {
            return null
        }

        return (
            <div>
                <Nav eventId={this.state.eventId} />
                <div id={event.eventId} className="event-page">
                    <div className="event-page-section top-event-page">
                        <Typography variant="h3" className={classes.title} id="event-title">
                            {event.name || "No-name Event"}
                        </Typography>

                        <div class="button-group">
                            <Button disabled={this.state.autoUpdate} className="event-button plus-one" variant="text" color="primary"
                            onClick={this.handleIncrease}>
                                +1
                            </Button>
                        </div>
                    </div>
                    <div className="event-page-section bottom-event-page">
                        <div class="button-group">
                            <Button disabled={this.state.autoUpdate} className="event-button minus-one" variant="text" color="secondary" onClick={this.handleDecrease}>
                                -1
                            </Button>
                        </div>
                    </div>

                    <Counter 
                    clicks={event.clicks}
                    maxEntry={event.maxEntry}
                    showMaxCap={event.showMaxCap}
                    />

                    {eventError !== null ? <DisplayEventError /> : null}
                </div>
                <div>
                    <Accordion disabled={!this.state.editMode}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        >
                        <Typography className={classes.heading}>Redigera Event</Typography>

                        </AccordionSummary>
                        <div className={classes.editEvent}>
                            <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleClickOpen}>
                                REMOVE
                            </Button>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={this.props.user.all_users}
                                getOptionLabel={(option) => option.email}
                                filterSelectedOptions
                                value={this.props.data.selectedEventSharing}
                                onChange={this.updateSharing}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Dela event"
                                    placeholder="dkm@d.kth.se"
                                />
                                )}
                            />
                            <Button variant="contained" color="primary" className={classes.button} onClick={this.setSharing}>
                                UPDATE
                            </Button>
                        </div>
                        
                    </Accordion>
                    <FormControlLabel
                        control={<Switch checked={this.state.editMode} onChange={this.handleChange} name="editMode" />}
                        label="Redigeringsläge"
                    />
                          <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this event?"}</DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Eventet kommer raderas för alltid och! Raderingen kan inte ändras!
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Avbryt
                            </Button>
                            <Button onClick={this.removeEvent} color="primary" autoFocus>
                                RADERA
                            </Button>
                            </DialogActions>
                        </Dialog>
                        
                </div>
            </div>
        )
    }
}

EventPage.propTypes = {
    removeEvent: PropTypes.func.isRequired,
    eventDecrease: PropTypes.func.isRequired,
    getEventData: PropTypes.func.isRequired,
    sendSharing: PropTypes.func.isRequired,
    updateSharing: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user,
    data: state.data,
    UI: state.ui,
    error: state.error,
})

const mapActionsToProps = {
    getEventData,
    eventDecrease,
    eventIncrease,
    removeEvent,
    getUsers,
    sendSharing,
    updateSharing,
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(EventPage))
