import React from 'react';
import { Typography } from '@material-ui/core'

const Counter = (props) => {
    const {clicks, maxEntry, showMaxCap} = props

    if (showMaxCap) 
        return (
            <Typography variant="h4" className={`clicks ${clicks === maxEntry ? 'max-entry' : ''}`}>
                <p>{clicks} / {maxEntry}</p>
            </Typography>   
        )
    else {
        const percentageSpaceLeft = 1 - clicks / maxEntry;
        let colorIndicator = "white";

        if (percentageSpaceLeft < 0.1) colorIndicator = "red";
        else if (percentageSpaceLeft < 0.2) colorIndicator = "orange";
        else if (percentageSpaceLeft < 0.3) colorIndicator = "yellow"
        
        return (
            <Typography id={props.id} variant="h4" className={`clicks ${clicks === maxEntry ? 'max-entry' : ''}`} style={{color: colorIndicator}}> 
                <p>{clicks}</p>
            </Typography>   
        )
    }

};

export default Counter;