export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER';
export const ALL_USERS = 'ALL_USERS';

export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const REDIRECT_TO = 'REDIRECT_TO'
export const LOADING_UI = 'LOADING_UI'

export const GET_ALL_EVENTS = 'GET_ALL_EVENTS'
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'

export const EVENT_ERROR = 'EVENT_ERROR'
export const CLEAR_EVENT_ERROR = 'CLEAR_EVENT_ERROR'
export const SET_EVENT_SHARING = 'SET_EVENT_SHARING';

export const SET_EVENT_UNSUBSCRIBE = 'EVENT_UNSUBSCRIBE'
export const REMOVE_EVENT_UNSUBSCRIBE = 'REMOVE_EVENT_UNSUBSCRIBE'
export const UNSUBSCRIBE_EVENT = 'UNSUBSCRIBE_EVENT'

export const UPDATE_EVENT = 'UPDATE_EVENT'

export const SET_GENERIC_ERROR = 'SET_GENERIC_ERROR'
export const REMOVE_ALL_ERRORS = 'REMOVE_ALL_ERRORS'

export const SET_404_ERROR = 'SET_404_ERROR';
export const SET_403_ERROR = 'SET_403_ERROR';

export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';

export const SIGNIN_LOAD = 'SIGNIN_LOAD'
export const SIGNIN_LOAD_DONE = 'SIGNIN_LOAD_DONE'
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'

export const SIGNUP_UNKNOWN_ERROR = 'SIGNUP_UNKNOWN_ERROR'
export const SIGNUP_PASSWORD_ERROR = 'SIGNUP_PASSWORD_ERROR'
export const SIGNUP_EMAIL_ERROR = 'SIGNUP_EMAIL_ERROR'
export const SIGNUP_NAME_ERROR = 'SIGNUP_NAME_ERROR'
export const SIGNUP_ACCOUNT_EXISTS = 'SIGNUP_ACCOUNT_EXISTS'
export const SIGNUP_CLEAR_ERRORS = 'SIGNUP_CLEAR_ERRORS'


// Egg
// export const EGG_UPDATE_HEART_CLICKS = 'UPDATE_HEART_CLICKS'
// export const EGG_UPDATE = 'EGG_UPDATE'