import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux' 
import { getViewEvent, eventIncrease, eventDecrease, removeEvent } from '../redux/actions/dataActions'
import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { DisplayEventError } from '../util/DisplayEventError'
import Counter from './event/Counter'
import Footer from './utils/Footer'
import Nav from './Navigation/Nav'

const styles = {
    title: {
        textAlign: "center",
    },
    back: {
        marginLeft: "10px",
        opacity: "75%",
    },
    editEvent: {
        width: '100%',
        display: 'flex',
        justifyContent: "center",
        minHeight: "200px"
    },
    button: {
        height: "50px"
    },
    live: {
        color: "white",
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        verticalAlign: "middle"
    }
}

class ViewEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticated: props.authenticated,
            eventId: null,
            autoUpdate: true,
            editMode: false,
            open: false
        }
    }

    componentDidMount() {
        const { match: { params }} = this.props
        this.setState({
            eventId: params.id
        })
        this.props.getViewEvent(params.id)
    }

    componentWillUnmount() {
        this.props.data.unsubscribe && this.props.data.unsubscribe()
    }

    render() {
        const event = this.props.data.selectedEvent
        const {classes} = this.props
        if (!event) {
            return null
        }

        const {eventError} = this.props.data

        return (
            <div>
                {this.state.authenticated && 
                    <Nav eventId={this.state.eventId}/>
                }
                <div id={event.eventId} className="event-page">
                    <Typography variant="h2" className={classes.title}>
                        {event.name || "No-name Event"}
                    </Typography>
                    <div className={classes.live}>
                        <div className="live-indicator"></div>
                        <Typography variant="h4" >
                            Live
                        </Typography>
                    </div>

                    <Counter 
                    clicks={event.clicks}
                    maxEntry={event.maxEntry}
                    showMaxCap={event.showMaxCap}
                    />

                    {eventError !== null ? <DisplayEventError /> : null}
                </div>
                <Footer transparent={true}/>
            </div>
        )
    }
}

ViewEvent.propTypes = {
    removeEvent: PropTypes.func.isRequired,
    eventDecrease: PropTypes.func.isRequired,
    getViewEvent: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    data: state.data,
    UI: state.ui
})

const mapActionsToProps = {
    getViewEvent,
    eventDecrease,
    eventIncrease,
    removeEvent
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ViewEvent))
