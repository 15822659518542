import {SIGNUP_UNKNOWN_ERROR,
    SIGNUP_PASSWORD_ERROR,
    SIGNUP_EMAIL_ERROR,
    SIGNUP_NAME_ERROR} from '../types'

export function getErrorType(errResponse) {
    const error = errResponse.data.error

    switch (error) {
        case "invalid/password":
            return SIGNUP_PASSWORD_ERROR
        case "invalid/name":
            return SIGNUP_NAME_ERROR;
        case "invalid/email":
            return SIGNUP_EMAIL_ERROR
        default:
            return SIGNUP_UNKNOWN_ERROR
    }
}

export function getErrorMessage(errResponse) {
    const error = errResponse.data.error

    switch (error) {
        case "invalid/password":
            return "Invalid password, try a different one!"
        case "invalid/name":
            return "First name and last name must be at least 2 characters each!";
        case "invalid/email":
            return "Email is invalid, try a different one!"
        default:
            return "An unknown error has ocurred, contact administrator!"
    }
}

